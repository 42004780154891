import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _28c7073d = () => interopDefault(import('../pages/candidate/index.vue' /* webpackChunkName: "pages/candidate/index" */))
const _5f10e61b = () => interopDefault(import('../pages/candidate-recruiter/index.vue' /* webpackChunkName: "pages/candidate-recruiter/index" */))
const _4ea3bb27 = () => interopDefault(import('../pages/client/index.vue' /* webpackChunkName: "pages/client/index" */))
const _0f89e0ee = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _7699e2de = () => interopDefault(import('../pages/landing/index.vue' /* webpackChunkName: "pages/landing/index" */))
const _2631c1b0 = () => interopDefault(import('../pages/messages/index.vue' /* webpackChunkName: "pages/messages/index" */))
const _8ea84e66 = () => interopDefault(import('../pages/opportunity/index.vue' /* webpackChunkName: "pages/opportunity/index" */))
const _a8606462 = () => interopDefault(import('../pages/organization/index.vue' /* webpackChunkName: "pages/organization/index" */))
const _53658e47 = () => interopDefault(import('../pages/recruiter-chatbot/index.vue' /* webpackChunkName: "pages/recruiter-chatbot/index" */))
const _1665ab06 = () => interopDefault(import('../pages/sales/index.vue' /* webpackChunkName: "pages/sales/index" */))
const _303b20c0 = () => interopDefault(import('../pages/talent-seeker/index.vue' /* webpackChunkName: "pages/talent-seeker/index" */))
const _8b2e9db8 = () => interopDefault(import('../pages/torre-geniuses/index.vue' /* webpackChunkName: "pages/torre-geniuses/index" */))
const _4c62ffa6 = () => interopDefault(import('../pages/utility/index.vue' /* webpackChunkName: "pages/utility/index" */))
const _02787222 = () => interopDefault(import('../pages/view-task-properties/index.vue' /* webpackChunkName: "pages/view-task-properties/index" */))
const _f911a70e = () => interopDefault(import('../pages/candidate-recruiter/assign/index.vue' /* webpackChunkName: "pages/candidate-recruiter/assign/index" */))
const _390ba5b2 = () => interopDefault(import('../pages/candidate-recruiter/decode-credentials/index.vue' /* webpackChunkName: "pages/candidate-recruiter/decode-credentials/index" */))
const _512b9504 = () => interopDefault(import('../pages/candidate-recruiter/import-candidates/index.vue' /* webpackChunkName: "pages/candidate-recruiter/import-candidates/index" */))
const _6762467d = () => interopDefault(import('../pages/candidate-recruiter/opportunities/index.vue' /* webpackChunkName: "pages/candidate-recruiter/opportunities/index" */))
const _4b82ce5a = () => interopDefault(import('../pages/candidate/candidate-external-undo-imported-resume/index.vue' /* webpackChunkName: "pages/candidate/candidate-external-undo-imported-resume/index" */))
const _5a5f1910 = () => interopDefault(import('../pages/candidate/candidate-information/index.vue' /* webpackChunkName: "pages/candidate/candidate-information/index" */))
const _3125ce02 = () => interopDefault(import('../pages/candidate/candidate-update-external-person-flags/index.vue' /* webpackChunkName: "pages/candidate/candidate-update-external-person-flags/index" */))
const _57bf0d0e = () => interopDefault(import('../pages/candidate/send-regular-message/index.vue' /* webpackChunkName: "pages/candidate/send-regular-message/index" */))
const _f25f2264 = () => interopDefault(import('../pages/candidate/send-urgent-message/index.vue' /* webpackChunkName: "pages/candidate/send-urgent-message/index" */))
const _c18143c8 = () => interopDefault(import('../pages/candidate/update-flags-access-cohort/index.vue' /* webpackChunkName: "pages/candidate/update-flags-access-cohort/index" */))
const _0b5e6854 = () => interopDefault(import('../pages/candidate/upload-test-result/index.vue' /* webpackChunkName: "pages/candidate/upload-test-result/index" */))
const _38cf0ee0 = () => interopDefault(import('../pages/client/clients/index.vue' /* webpackChunkName: "pages/client/clients/index" */))
const _dac3d262 = () => interopDefault(import('../pages/client/detail/index.vue' /* webpackChunkName: "pages/client/detail/index" */))
const _14297dae = () => interopDefault(import('../pages/messages/email-inbox/index.vue' /* webpackChunkName: "pages/messages/email-inbox/index" */))
const _4494cee0 = () => interopDefault(import('../pages/messages/email-signature/index.vue' /* webpackChunkName: "pages/messages/email-signature/index" */))
const _5cbcd5ba = () => interopDefault(import('../pages/messages/message-tickets/index.vue' /* webpackChunkName: "pages/messages/message-tickets/index" */))
const _f50ea142 = () => interopDefault(import('../pages/messages/send-message/index.vue' /* webpackChunkName: "pages/messages/send-message/index" */))
const _54b1a922 = () => interopDefault(import('../pages/opportunity/automated-tasks/index.vue' /* webpackChunkName: "pages/opportunity/automated-tasks/index" */))
const _0ca33b76 = () => interopDefault(import('../pages/opportunity/data-point/index.vue' /* webpackChunkName: "pages/opportunity/data-point/index" */))
const _3e510720 = () => interopDefault(import('../pages/opportunity/deadline/index.vue' /* webpackChunkName: "pages/opportunity/deadline/index" */))
const _727875d8 = () => interopDefault(import('../pages/opportunity/evaluation-state/index.vue' /* webpackChunkName: "pages/opportunity/evaluation-state/index" */))
const _9c1ffc94 = () => interopDefault(import('../pages/opportunity/history-diff/index.vue' /* webpackChunkName: "pages/opportunity/history-diff/index" */))
const _478aae7d = () => interopDefault(import('../pages/opportunity/manage/index.vue' /* webpackChunkName: "pages/opportunity/manage/index" */))
const _031d94e6 = () => interopDefault(import('../pages/opportunity/manager-url/index.vue' /* webpackChunkName: "pages/opportunity/manager-url/index" */))
const _6b246566 = () => interopDefault(import('../pages/opportunity/new-approve-experimental/index.vue' /* webpackChunkName: "pages/opportunity/new-approve-experimental/index" */))
const _2cb7ad67 = () => interopDefault(import('../pages/opportunity/reassign-opportunity-operator/index.vue' /* webpackChunkName: "pages/opportunity/reassign-opportunity-operator/index" */))
const _80aa830e = () => interopDefault(import('../pages/opportunity/recruitment-card/index.vue' /* webpackChunkName: "pages/opportunity/recruitment-card/index" */))
const _4df25743 = () => interopDefault(import('../pages/opportunity/reflection/index.vue' /* webpackChunkName: "pages/opportunity/reflection/index" */))
const _bbc42d68 = () => interopDefault(import('../pages/opportunity/tasks/index.vue' /* webpackChunkName: "pages/opportunity/tasks/index" */))
const _6ed3e01f = () => interopDefault(import('../pages/opportunity/tests/index.vue' /* webpackChunkName: "pages/opportunity/tests/index" */))
const _66bb733b = () => interopDefault(import('../pages/organization/manage/index.vue' /* webpackChunkName: "pages/organization/manage/index" */))
const _43c43fc1 = () => interopDefault(import('../pages/recruiter-chatbot/applications/index.vue' /* webpackChunkName: "pages/recruiter-chatbot/applications/index" */))
const _3de70ff1 = () => interopDefault(import('../pages/recruiter-chatbot/build-screening-questions-url/index.vue' /* webpackChunkName: "pages/recruiter-chatbot/build-screening-questions-url/index" */))
const _5811f766 = () => interopDefault(import('../pages/recruiter-chatbot/candidate-preferences/index.vue' /* webpackChunkName: "pages/recruiter-chatbot/candidate-preferences/index" */))
const _f0be088a = () => interopDefault(import('../pages/recruiter-chatbot/ccg-emails/index.vue' /* webpackChunkName: "pages/recruiter-chatbot/ccg-emails/index" */))
const _73208628 = () => interopDefault(import('../pages/recruiter-chatbot/external-flow/index.vue' /* webpackChunkName: "pages/recruiter-chatbot/external-flow/index" */))
const _4c95a158 = () => interopDefault(import('../pages/recruiter-chatbot/external-sourcing-request/index.vue' /* webpackChunkName: "pages/recruiter-chatbot/external-sourcing-request/index" */))
const _18713d80 = () => interopDefault(import('../pages/recruiter-chatbot/manual-invite-sourcing-candidates/index.vue' /* webpackChunkName: "pages/recruiter-chatbot/manual-invite-sourcing-candidates/index" */))
const _2fb5fb05 = () => interopDefault(import('../pages/recruiter-chatbot/mark-candidate-not-interested/index.vue' /* webpackChunkName: "pages/recruiter-chatbot/mark-candidate-not-interested/index" */))
const _0dcf214f = () => interopDefault(import('../pages/recruiter-chatbot/postpone-notifications/index.vue' /* webpackChunkName: "pages/recruiter-chatbot/postpone-notifications/index" */))
const _572df35b = () => interopDefault(import('../pages/recruiter-chatbot/update-connection-status/index.vue' /* webpackChunkName: "pages/recruiter-chatbot/update-connection-status/index" */))
const _0c4527fb = () => interopDefault(import('../pages/recruiter-chatbot/verification-request/index.vue' /* webpackChunkName: "pages/recruiter-chatbot/verification-request/index" */))
const _321e3d1a = () => interopDefault(import('../pages/sales/agile/index.vue' /* webpackChunkName: "pages/sales/agile/index" */))
const _2fc01cd8 = () => interopDefault(import('../pages/talent-seeker/assign-account-manager/index.vue' /* webpackChunkName: "pages/talent-seeker/assign-account-manager/index" */))
const _b6d32c36 = () => interopDefault(import('../pages/talent-seeker/create-public-ccg/index.vue' /* webpackChunkName: "pages/talent-seeker/create-public-ccg/index" */))
const _6e6430f0 = () => interopDefault(import('../pages/talent-seeker/genome-token/index.vue' /* webpackChunkName: "pages/talent-seeker/genome-token/index" */))
const _048d24d4 = () => interopDefault(import('../pages/talent-seeker/talent-seeker-information/index.vue' /* webpackChunkName: "pages/talent-seeker/talent-seeker-information/index" */))
const _ba2176a8 = () => interopDefault(import('../pages/talent-seeker/update-early-access-flag/index.vue' /* webpackChunkName: "pages/talent-seeker/update-early-access-flag/index" */))
const _5322f6ee = () => interopDefault(import('../pages/talent-seeker/update-onboarded-status/index.vue' /* webpackChunkName: "pages/talent-seeker/update-onboarded-status/index" */))
const _25553de4 = () => interopDefault(import('../pages/utility/active-subscriptions/index.vue' /* webpackChunkName: "pages/utility/active-subscriptions/index" */))
const _45172557 = () => interopDefault(import('../pages/utility/all-messages/index.vue' /* webpackChunkName: "pages/utility/all-messages/index" */))
const _157f7ee6 = () => interopDefault(import('../pages/utility/operators/index.vue' /* webpackChunkName: "pages/utility/operators/index" */))
const _2a25a220 = () => interopDefault(import('../pages/utility/opp-approval-request-logs/index.vue' /* webpackChunkName: "pages/utility/opp-approval-request-logs/index" */))
const _c7b4732e = () => interopDefault(import('../pages/utility/permission-groups/index.vue' /* webpackChunkName: "pages/utility/permission-groups/index" */))
const _270ee9db = () => interopDefault(import('../pages/utility/permissions/index.vue' /* webpackChunkName: "pages/utility/permissions/index" */))
const _c72085dc = () => interopDefault(import('../pages/utility/task-properties/index.vue' /* webpackChunkName: "pages/utility/task-properties/index" */))
const _7a7a92eb = () => interopDefault(import('../pages/utility/torre-messenger-logs/index.vue' /* webpackChunkName: "pages/utility/torre-messenger-logs/index" */))
const _a59ee3d0 = () => interopDefault(import('../pages/utility/update-person-operator-flag/index.vue' /* webpackChunkName: "pages/utility/update-person-operator-flag/index" */))
const _37ae7bc8 = () => interopDefault(import('../pages/client/detail/Components.ts' /* webpackChunkName: "pages/client/detail/Components" */))
const _93735634 = () => interopDefault(import('../pages/opportunity/recruitment-card/components.ts' /* webpackChunkName: "pages/opportunity/recruitment-card/components" */))
const _180309f8 = () => interopDefault(import('../pages/opportunity/recruitment-card/processSteps.ts' /* webpackChunkName: "pages/opportunity/recruitment-card/processSteps" */))
const _f7b6f26e = () => interopDefault(import('../pages/messages/emma/_entity.vue' /* webpackChunkName: "pages/messages/emma/_entity" */))
const _cba60a5a = () => interopDefault(import('../pages/messages/gpt-prompt/_entity.vue' /* webpackChunkName: "pages/messages/gpt-prompt/_entity" */))
const _182c3b30 = () => interopDefault(import('../pages/torre-geniuses/onboarding-flow/_flow.vue' /* webpackChunkName: "pages/torre-geniuses/onboarding-flow/_flow" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/candidate",
    component: _28c7073d,
    name: "candidate___en___default"
  }, {
    path: "/candidate-recruiter",
    component: _5f10e61b,
    name: "candidate-recruiter___en___default"
  }, {
    path: "/client",
    component: _4ea3bb27,
    name: "client___en___default"
  }, {
    path: "/en",
    component: _0f89e0ee,
    name: "index___en"
  }, {
    path: "/es",
    component: _0f89e0ee,
    name: "index___es"
  }, {
    path: "/landing",
    component: _7699e2de,
    name: "landing___en___default"
  }, {
    path: "/messages",
    component: _2631c1b0,
    name: "messages___en___default"
  }, {
    path: "/opportunity",
    component: _8ea84e66,
    name: "opportunity___en___default"
  }, {
    path: "/organization",
    component: _a8606462,
    name: "organization___en___default"
  }, {
    path: "/recruiter-chatbot",
    component: _53658e47,
    name: "recruiter-chatbot___en___default"
  }, {
    path: "/sales",
    component: _1665ab06,
    name: "sales___en___default"
  }, {
    path: "/talent-seeker",
    component: _303b20c0,
    name: "talent-seeker___en___default"
  }, {
    path: "/torre-geniuses",
    component: _8b2e9db8,
    name: "torre-geniuses___en___default"
  }, {
    path: "/utility",
    component: _4c62ffa6,
    name: "utility___en___default"
  }, {
    path: "/view-task-properties",
    component: _02787222,
    name: "view-task-properties___en___default"
  }, {
    path: "/candidate-recruiter/assign",
    component: _f911a70e,
    name: "candidate-recruiter-assign___en___default"
  }, {
    path: "/candidate-recruiter/decode-credentials",
    component: _390ba5b2,
    name: "candidate-recruiter-decode-credentials___en___default"
  }, {
    path: "/candidate-recruiter/import-candidates",
    component: _512b9504,
    name: "candidate-recruiter-import-candidates___en___default"
  }, {
    path: "/candidate-recruiter/opportunities",
    component: _6762467d,
    name: "candidate-recruiter-opportunities___en___default"
  }, {
    path: "/candidate/candidate-external-undo-imported-resume",
    component: _4b82ce5a,
    name: "candidate-candidate-external-undo-imported-resume___en___default"
  }, {
    path: "/candidate/candidate-information",
    component: _5a5f1910,
    name: "candidate-candidate-information___en___default"
  }, {
    path: "/candidate/candidate-update-external-person-flags",
    component: _3125ce02,
    name: "candidate-candidate-update-external-person-flags___en___default"
  }, {
    path: "/candidate/send-regular-message",
    component: _57bf0d0e,
    name: "candidate-send-regular-message___en___default"
  }, {
    path: "/candidate/send-urgent-message",
    component: _f25f2264,
    name: "candidate-send-urgent-message___en___default"
  }, {
    path: "/candidate/update-flags-access-cohort",
    component: _c18143c8,
    name: "candidate-update-flags-access-cohort___en___default"
  }, {
    path: "/candidate/upload-test-result",
    component: _0b5e6854,
    name: "candidate-upload-test-result___en___default"
  }, {
    path: "/client/clients",
    component: _38cf0ee0,
    name: "client-clients___en___default"
  }, {
    path: "/client/detail",
    component: _dac3d262,
    name: "client-detail___en___default"
  }, {
    path: "/en/candidate",
    component: _28c7073d,
    name: "candidate___en"
  }, {
    path: "/en/candidate-recruiter",
    component: _5f10e61b,
    name: "candidate-recruiter___en"
  }, {
    path: "/en/client",
    component: _4ea3bb27,
    name: "client___en"
  }, {
    path: "/en/landing",
    component: _7699e2de,
    name: "landing___en"
  }, {
    path: "/en/messages",
    component: _2631c1b0,
    name: "messages___en"
  }, {
    path: "/en/opportunity",
    component: _8ea84e66,
    name: "opportunity___en"
  }, {
    path: "/en/organization",
    component: _a8606462,
    name: "organization___en"
  }, {
    path: "/en/recruiter-chatbot",
    component: _53658e47,
    name: "recruiter-chatbot___en"
  }, {
    path: "/en/sales",
    component: _1665ab06,
    name: "sales___en"
  }, {
    path: "/en/talent-seeker",
    component: _303b20c0,
    name: "talent-seeker___en"
  }, {
    path: "/en/torre-geniuses",
    component: _8b2e9db8,
    name: "torre-geniuses___en"
  }, {
    path: "/en/utility",
    component: _4c62ffa6,
    name: "utility___en"
  }, {
    path: "/en/view-task-properties",
    component: _02787222,
    name: "view-task-properties___en"
  }, {
    path: "/es/candidate",
    component: _28c7073d,
    name: "candidate___es"
  }, {
    path: "/es/candidate-recruiter",
    component: _5f10e61b,
    name: "candidate-recruiter___es"
  }, {
    path: "/es/client",
    component: _4ea3bb27,
    name: "client___es"
  }, {
    path: "/es/landing",
    component: _7699e2de,
    name: "landing___es"
  }, {
    path: "/es/messages",
    component: _2631c1b0,
    name: "messages___es"
  }, {
    path: "/es/opportunity",
    component: _8ea84e66,
    name: "opportunity___es"
  }, {
    path: "/es/organization",
    component: _a8606462,
    name: "organization___es"
  }, {
    path: "/es/recruiter-chatbot",
    component: _53658e47,
    name: "recruiter-chatbot___es"
  }, {
    path: "/es/sales",
    component: _1665ab06,
    name: "sales___es"
  }, {
    path: "/es/talent-seeker",
    component: _303b20c0,
    name: "talent-seeker___es"
  }, {
    path: "/es/torre-geniuses",
    component: _8b2e9db8,
    name: "torre-geniuses___es"
  }, {
    path: "/es/utility",
    component: _4c62ffa6,
    name: "utility___es"
  }, {
    path: "/es/view-task-properties",
    component: _02787222,
    name: "view-task-properties___es"
  }, {
    path: "/messages/email-inbox",
    component: _14297dae,
    name: "messages-email-inbox___en___default"
  }, {
    path: "/messages/email-signature",
    component: _4494cee0,
    name: "messages-email-signature___en___default"
  }, {
    path: "/messages/message-tickets",
    component: _5cbcd5ba,
    name: "messages-message-tickets___en___default"
  }, {
    path: "/messages/send-message",
    component: _f50ea142,
    name: "messages-send-message___en___default"
  }, {
    path: "/opportunity/automated-tasks",
    component: _54b1a922,
    name: "opportunity-automated-tasks___en___default"
  }, {
    path: "/opportunity/data-point",
    component: _0ca33b76,
    name: "opportunity-data-point___en___default"
  }, {
    path: "/opportunity/deadline",
    component: _3e510720,
    name: "opportunity-deadline___en___default"
  }, {
    path: "/opportunity/evaluation-state",
    component: _727875d8,
    name: "opportunity-evaluation-state___en___default"
  }, {
    path: "/opportunity/history-diff",
    component: _9c1ffc94,
    name: "opportunity-history-diff___en___default"
  }, {
    path: "/opportunity/manage",
    component: _478aae7d,
    name: "opportunity-manage___en___default"
  }, {
    path: "/opportunity/manager-url",
    component: _031d94e6,
    name: "opportunity-manager-url___en___default"
  }, {
    path: "/opportunity/new-approve-experimental",
    component: _6b246566,
    name: "opportunity-new-approve-experimental___en___default"
  }, {
    path: "/opportunity/reassign-opportunity-operator",
    component: _2cb7ad67,
    name: "opportunity-reassign-opportunity-operator___en___default"
  }, {
    path: "/opportunity/recruitment-card",
    component: _80aa830e,
    name: "opportunity-recruitment-card___en___default"
  }, {
    path: "/opportunity/reflection",
    component: _4df25743,
    name: "opportunity-reflection___en___default"
  }, {
    path: "/opportunity/tasks",
    component: _bbc42d68,
    name: "opportunity-tasks___en___default"
  }, {
    path: "/opportunity/tests",
    component: _6ed3e01f,
    name: "opportunity-tests___en___default"
  }, {
    path: "/organization/manage",
    component: _66bb733b,
    name: "organization-manage___en___default"
  }, {
    path: "/recruiter-chatbot/applications",
    component: _43c43fc1,
    name: "recruiter-chatbot-applications___en___default"
  }, {
    path: "/recruiter-chatbot/build-screening-questions-url",
    component: _3de70ff1,
    name: "recruiter-chatbot-build-screening-questions-url___en___default"
  }, {
    path: "/recruiter-chatbot/candidate-preferences",
    component: _5811f766,
    name: "recruiter-chatbot-candidate-preferences___en___default"
  }, {
    path: "/recruiter-chatbot/ccg-emails",
    component: _f0be088a,
    name: "recruiter-chatbot-ccg-emails___en___default"
  }, {
    path: "/recruiter-chatbot/external-flow",
    component: _73208628,
    name: "recruiter-chatbot-external-flow___en___default"
  }, {
    path: "/recruiter-chatbot/external-sourcing-request",
    component: _4c95a158,
    name: "recruiter-chatbot-external-sourcing-request___en___default"
  }, {
    path: "/recruiter-chatbot/manual-invite-sourcing-candidates",
    component: _18713d80,
    name: "recruiter-chatbot-manual-invite-sourcing-candidates___en___default"
  }, {
    path: "/recruiter-chatbot/mark-candidate-not-interested",
    component: _2fb5fb05,
    name: "recruiter-chatbot-mark-candidate-not-interested___en___default"
  }, {
    path: "/recruiter-chatbot/postpone-notifications",
    component: _0dcf214f,
    name: "recruiter-chatbot-postpone-notifications___en___default"
  }, {
    path: "/recruiter-chatbot/update-connection-status",
    component: _572df35b,
    name: "recruiter-chatbot-update-connection-status___en___default"
  }, {
    path: "/recruiter-chatbot/verification-request",
    component: _0c4527fb,
    name: "recruiter-chatbot-verification-request___en___default"
  }, {
    path: "/sales/agile",
    component: _321e3d1a,
    name: "sales-agile___en___default"
  }, {
    path: "/talent-seeker/assign-account-manager",
    component: _2fc01cd8,
    name: "talent-seeker-assign-account-manager___en___default"
  }, {
    path: "/talent-seeker/create-public-ccg",
    component: _b6d32c36,
    name: "talent-seeker-create-public-ccg___en___default"
  }, {
    path: "/talent-seeker/genome-token",
    component: _6e6430f0,
    name: "talent-seeker-genome-token___en___default"
  }, {
    path: "/talent-seeker/talent-seeker-information",
    component: _048d24d4,
    name: "talent-seeker-talent-seeker-information___en___default"
  }, {
    path: "/talent-seeker/update-early-access-flag",
    component: _ba2176a8,
    name: "talent-seeker-update-early-access-flag___en___default"
  }, {
    path: "/talent-seeker/update-onboarded-status",
    component: _5322f6ee,
    name: "talent-seeker-update-onboarded-status___en___default"
  }, {
    path: "/utility/active-subscriptions",
    component: _25553de4,
    name: "utility-active-subscriptions___en___default"
  }, {
    path: "/utility/all-messages",
    component: _45172557,
    name: "utility-all-messages___en___default"
  }, {
    path: "/utility/operators",
    component: _157f7ee6,
    name: "utility-operators___en___default"
  }, {
    path: "/utility/opp-approval-request-logs",
    component: _2a25a220,
    name: "utility-opp-approval-request-logs___en___default"
  }, {
    path: "/utility/permission-groups",
    component: _c7b4732e,
    name: "utility-permission-groups___en___default"
  }, {
    path: "/utility/permissions",
    component: _270ee9db,
    name: "utility-permissions___en___default"
  }, {
    path: "/utility/task-properties",
    component: _c72085dc,
    name: "utility-task-properties___en___default"
  }, {
    path: "/utility/torre-messenger-logs",
    component: _7a7a92eb,
    name: "utility-torre-messenger-logs___en___default"
  }, {
    path: "/utility/update-person-operator-flag",
    component: _a59ee3d0,
    name: "utility-update-person-operator-flag___en___default"
  }, {
    path: "/client/detail/Components",
    component: _37ae7bc8,
    name: "client-detail-Components___en___default"
  }, {
    path: "/en/candidate-recruiter/assign",
    component: _f911a70e,
    name: "candidate-recruiter-assign___en"
  }, {
    path: "/en/candidate-recruiter/decode-credentials",
    component: _390ba5b2,
    name: "candidate-recruiter-decode-credentials___en"
  }, {
    path: "/en/candidate-recruiter/import-candidates",
    component: _512b9504,
    name: "candidate-recruiter-import-candidates___en"
  }, {
    path: "/en/candidate-recruiter/opportunities",
    component: _6762467d,
    name: "candidate-recruiter-opportunities___en"
  }, {
    path: "/en/candidate/candidate-external-undo-imported-resume",
    component: _4b82ce5a,
    name: "candidate-candidate-external-undo-imported-resume___en"
  }, {
    path: "/en/candidate/candidate-information",
    component: _5a5f1910,
    name: "candidate-candidate-information___en"
  }, {
    path: "/en/candidate/candidate-update-external-person-flags",
    component: _3125ce02,
    name: "candidate-candidate-update-external-person-flags___en"
  }, {
    path: "/en/candidate/send-regular-message",
    component: _57bf0d0e,
    name: "candidate-send-regular-message___en"
  }, {
    path: "/en/candidate/send-urgent-message",
    component: _f25f2264,
    name: "candidate-send-urgent-message___en"
  }, {
    path: "/en/candidate/update-flags-access-cohort",
    component: _c18143c8,
    name: "candidate-update-flags-access-cohort___en"
  }, {
    path: "/en/candidate/upload-test-result",
    component: _0b5e6854,
    name: "candidate-upload-test-result___en"
  }, {
    path: "/en/client/clients",
    component: _38cf0ee0,
    name: "client-clients___en"
  }, {
    path: "/en/client/detail",
    component: _dac3d262,
    name: "client-detail___en"
  }, {
    path: "/en/messages/email-inbox",
    component: _14297dae,
    name: "messages-email-inbox___en"
  }, {
    path: "/en/messages/email-signature",
    component: _4494cee0,
    name: "messages-email-signature___en"
  }, {
    path: "/en/messages/message-tickets",
    component: _5cbcd5ba,
    name: "messages-message-tickets___en"
  }, {
    path: "/en/messages/send-message",
    component: _f50ea142,
    name: "messages-send-message___en"
  }, {
    path: "/en/opportunity/automated-tasks",
    component: _54b1a922,
    name: "opportunity-automated-tasks___en"
  }, {
    path: "/en/opportunity/data-point",
    component: _0ca33b76,
    name: "opportunity-data-point___en"
  }, {
    path: "/en/opportunity/deadline",
    component: _3e510720,
    name: "opportunity-deadline___en"
  }, {
    path: "/en/opportunity/evaluation-state",
    component: _727875d8,
    name: "opportunity-evaluation-state___en"
  }, {
    path: "/en/opportunity/history-diff",
    component: _9c1ffc94,
    name: "opportunity-history-diff___en"
  }, {
    path: "/en/opportunity/manage",
    component: _478aae7d,
    name: "opportunity-manage___en"
  }, {
    path: "/en/opportunity/manager-url",
    component: _031d94e6,
    name: "opportunity-manager-url___en"
  }, {
    path: "/en/opportunity/new-approve-experimental",
    component: _6b246566,
    name: "opportunity-new-approve-experimental___en"
  }, {
    path: "/en/opportunity/reassign-opportunity-operator",
    component: _2cb7ad67,
    name: "opportunity-reassign-opportunity-operator___en"
  }, {
    path: "/en/opportunity/recruitment-card",
    component: _80aa830e,
    name: "opportunity-recruitment-card___en"
  }, {
    path: "/en/opportunity/reflection",
    component: _4df25743,
    name: "opportunity-reflection___en"
  }, {
    path: "/en/opportunity/tasks",
    component: _bbc42d68,
    name: "opportunity-tasks___en"
  }, {
    path: "/en/opportunity/tests",
    component: _6ed3e01f,
    name: "opportunity-tests___en"
  }, {
    path: "/en/organization/manage",
    component: _66bb733b,
    name: "organization-manage___en"
  }, {
    path: "/en/recruiter-chatbot/applications",
    component: _43c43fc1,
    name: "recruiter-chatbot-applications___en"
  }, {
    path: "/en/recruiter-chatbot/build-screening-questions-url",
    component: _3de70ff1,
    name: "recruiter-chatbot-build-screening-questions-url___en"
  }, {
    path: "/en/recruiter-chatbot/candidate-preferences",
    component: _5811f766,
    name: "recruiter-chatbot-candidate-preferences___en"
  }, {
    path: "/en/recruiter-chatbot/ccg-emails",
    component: _f0be088a,
    name: "recruiter-chatbot-ccg-emails___en"
  }, {
    path: "/en/recruiter-chatbot/external-flow",
    component: _73208628,
    name: "recruiter-chatbot-external-flow___en"
  }, {
    path: "/en/recruiter-chatbot/external-sourcing-request",
    component: _4c95a158,
    name: "recruiter-chatbot-external-sourcing-request___en"
  }, {
    path: "/en/recruiter-chatbot/manual-invite-sourcing-candidates",
    component: _18713d80,
    name: "recruiter-chatbot-manual-invite-sourcing-candidates___en"
  }, {
    path: "/en/recruiter-chatbot/mark-candidate-not-interested",
    component: _2fb5fb05,
    name: "recruiter-chatbot-mark-candidate-not-interested___en"
  }, {
    path: "/en/recruiter-chatbot/postpone-notifications",
    component: _0dcf214f,
    name: "recruiter-chatbot-postpone-notifications___en"
  }, {
    path: "/en/recruiter-chatbot/update-connection-status",
    component: _572df35b,
    name: "recruiter-chatbot-update-connection-status___en"
  }, {
    path: "/en/recruiter-chatbot/verification-request",
    component: _0c4527fb,
    name: "recruiter-chatbot-verification-request___en"
  }, {
    path: "/en/sales/agile",
    component: _321e3d1a,
    name: "sales-agile___en"
  }, {
    path: "/en/talent-seeker/assign-account-manager",
    component: _2fc01cd8,
    name: "talent-seeker-assign-account-manager___en"
  }, {
    path: "/en/talent-seeker/create-public-ccg",
    component: _b6d32c36,
    name: "talent-seeker-create-public-ccg___en"
  }, {
    path: "/en/talent-seeker/genome-token",
    component: _6e6430f0,
    name: "talent-seeker-genome-token___en"
  }, {
    path: "/en/talent-seeker/talent-seeker-information",
    component: _048d24d4,
    name: "talent-seeker-talent-seeker-information___en"
  }, {
    path: "/en/talent-seeker/update-early-access-flag",
    component: _ba2176a8,
    name: "talent-seeker-update-early-access-flag___en"
  }, {
    path: "/en/talent-seeker/update-onboarded-status",
    component: _5322f6ee,
    name: "talent-seeker-update-onboarded-status___en"
  }, {
    path: "/en/utility/active-subscriptions",
    component: _25553de4,
    name: "utility-active-subscriptions___en"
  }, {
    path: "/en/utility/all-messages",
    component: _45172557,
    name: "utility-all-messages___en"
  }, {
    path: "/en/utility/operators",
    component: _157f7ee6,
    name: "utility-operators___en"
  }, {
    path: "/en/utility/opp-approval-request-logs",
    component: _2a25a220,
    name: "utility-opp-approval-request-logs___en"
  }, {
    path: "/en/utility/permission-groups",
    component: _c7b4732e,
    name: "utility-permission-groups___en"
  }, {
    path: "/en/utility/permissions",
    component: _270ee9db,
    name: "utility-permissions___en"
  }, {
    path: "/en/utility/task-properties",
    component: _c72085dc,
    name: "utility-task-properties___en"
  }, {
    path: "/en/utility/torre-messenger-logs",
    component: _7a7a92eb,
    name: "utility-torre-messenger-logs___en"
  }, {
    path: "/en/utility/update-person-operator-flag",
    component: _a59ee3d0,
    name: "utility-update-person-operator-flag___en"
  }, {
    path: "/es/candidate-recruiter/assign",
    component: _f911a70e,
    name: "candidate-recruiter-assign___es"
  }, {
    path: "/es/candidate-recruiter/decode-credentials",
    component: _390ba5b2,
    name: "candidate-recruiter-decode-credentials___es"
  }, {
    path: "/es/candidate-recruiter/import-candidates",
    component: _512b9504,
    name: "candidate-recruiter-import-candidates___es"
  }, {
    path: "/es/candidate-recruiter/opportunities",
    component: _6762467d,
    name: "candidate-recruiter-opportunities___es"
  }, {
    path: "/es/candidate/candidate-external-undo-imported-resume",
    component: _4b82ce5a,
    name: "candidate-candidate-external-undo-imported-resume___es"
  }, {
    path: "/es/candidate/candidate-information",
    component: _5a5f1910,
    name: "candidate-candidate-information___es"
  }, {
    path: "/es/candidate/candidate-update-external-person-flags",
    component: _3125ce02,
    name: "candidate-candidate-update-external-person-flags___es"
  }, {
    path: "/es/candidate/send-regular-message",
    component: _57bf0d0e,
    name: "candidate-send-regular-message___es"
  }, {
    path: "/es/candidate/send-urgent-message",
    component: _f25f2264,
    name: "candidate-send-urgent-message___es"
  }, {
    path: "/es/candidate/update-flags-access-cohort",
    component: _c18143c8,
    name: "candidate-update-flags-access-cohort___es"
  }, {
    path: "/es/candidate/upload-test-result",
    component: _0b5e6854,
    name: "candidate-upload-test-result___es"
  }, {
    path: "/es/client/clients",
    component: _38cf0ee0,
    name: "client-clients___es"
  }, {
    path: "/es/client/detail",
    component: _dac3d262,
    name: "client-detail___es"
  }, {
    path: "/es/messages/email-inbox",
    component: _14297dae,
    name: "messages-email-inbox___es"
  }, {
    path: "/es/messages/email-signature",
    component: _4494cee0,
    name: "messages-email-signature___es"
  }, {
    path: "/es/messages/message-tickets",
    component: _5cbcd5ba,
    name: "messages-message-tickets___es"
  }, {
    path: "/es/messages/send-message",
    component: _f50ea142,
    name: "messages-send-message___es"
  }, {
    path: "/es/opportunity/automated-tasks",
    component: _54b1a922,
    name: "opportunity-automated-tasks___es"
  }, {
    path: "/es/opportunity/data-point",
    component: _0ca33b76,
    name: "opportunity-data-point___es"
  }, {
    path: "/es/opportunity/deadline",
    component: _3e510720,
    name: "opportunity-deadline___es"
  }, {
    path: "/es/opportunity/evaluation-state",
    component: _727875d8,
    name: "opportunity-evaluation-state___es"
  }, {
    path: "/es/opportunity/history-diff",
    component: _9c1ffc94,
    name: "opportunity-history-diff___es"
  }, {
    path: "/es/opportunity/manage",
    component: _478aae7d,
    name: "opportunity-manage___es"
  }, {
    path: "/es/opportunity/manager-url",
    component: _031d94e6,
    name: "opportunity-manager-url___es"
  }, {
    path: "/es/opportunity/new-approve-experimental",
    component: _6b246566,
    name: "opportunity-new-approve-experimental___es"
  }, {
    path: "/es/opportunity/reassign-opportunity-operator",
    component: _2cb7ad67,
    name: "opportunity-reassign-opportunity-operator___es"
  }, {
    path: "/es/opportunity/recruitment-card",
    component: _80aa830e,
    name: "opportunity-recruitment-card___es"
  }, {
    path: "/es/opportunity/reflection",
    component: _4df25743,
    name: "opportunity-reflection___es"
  }, {
    path: "/es/opportunity/tasks",
    component: _bbc42d68,
    name: "opportunity-tasks___es"
  }, {
    path: "/es/opportunity/tests",
    component: _6ed3e01f,
    name: "opportunity-tests___es"
  }, {
    path: "/es/organization/manage",
    component: _66bb733b,
    name: "organization-manage___es"
  }, {
    path: "/es/recruiter-chatbot/applications",
    component: _43c43fc1,
    name: "recruiter-chatbot-applications___es"
  }, {
    path: "/es/recruiter-chatbot/build-screening-questions-url",
    component: _3de70ff1,
    name: "recruiter-chatbot-build-screening-questions-url___es"
  }, {
    path: "/es/recruiter-chatbot/candidate-preferences",
    component: _5811f766,
    name: "recruiter-chatbot-candidate-preferences___es"
  }, {
    path: "/es/recruiter-chatbot/ccg-emails",
    component: _f0be088a,
    name: "recruiter-chatbot-ccg-emails___es"
  }, {
    path: "/es/recruiter-chatbot/external-flow",
    component: _73208628,
    name: "recruiter-chatbot-external-flow___es"
  }, {
    path: "/es/recruiter-chatbot/external-sourcing-request",
    component: _4c95a158,
    name: "recruiter-chatbot-external-sourcing-request___es"
  }, {
    path: "/es/recruiter-chatbot/manual-invite-sourcing-candidates",
    component: _18713d80,
    name: "recruiter-chatbot-manual-invite-sourcing-candidates___es"
  }, {
    path: "/es/recruiter-chatbot/mark-candidate-not-interested",
    component: _2fb5fb05,
    name: "recruiter-chatbot-mark-candidate-not-interested___es"
  }, {
    path: "/es/recruiter-chatbot/postpone-notifications",
    component: _0dcf214f,
    name: "recruiter-chatbot-postpone-notifications___es"
  }, {
    path: "/es/recruiter-chatbot/update-connection-status",
    component: _572df35b,
    name: "recruiter-chatbot-update-connection-status___es"
  }, {
    path: "/es/recruiter-chatbot/verification-request",
    component: _0c4527fb,
    name: "recruiter-chatbot-verification-request___es"
  }, {
    path: "/es/sales/agile",
    component: _321e3d1a,
    name: "sales-agile___es"
  }, {
    path: "/es/talent-seeker/assign-account-manager",
    component: _2fc01cd8,
    name: "talent-seeker-assign-account-manager___es"
  }, {
    path: "/es/talent-seeker/create-public-ccg",
    component: _b6d32c36,
    name: "talent-seeker-create-public-ccg___es"
  }, {
    path: "/es/talent-seeker/genome-token",
    component: _6e6430f0,
    name: "talent-seeker-genome-token___es"
  }, {
    path: "/es/talent-seeker/talent-seeker-information",
    component: _048d24d4,
    name: "talent-seeker-talent-seeker-information___es"
  }, {
    path: "/es/talent-seeker/update-early-access-flag",
    component: _ba2176a8,
    name: "talent-seeker-update-early-access-flag___es"
  }, {
    path: "/es/talent-seeker/update-onboarded-status",
    component: _5322f6ee,
    name: "talent-seeker-update-onboarded-status___es"
  }, {
    path: "/es/utility/active-subscriptions",
    component: _25553de4,
    name: "utility-active-subscriptions___es"
  }, {
    path: "/es/utility/all-messages",
    component: _45172557,
    name: "utility-all-messages___es"
  }, {
    path: "/es/utility/operators",
    component: _157f7ee6,
    name: "utility-operators___es"
  }, {
    path: "/es/utility/opp-approval-request-logs",
    component: _2a25a220,
    name: "utility-opp-approval-request-logs___es"
  }, {
    path: "/es/utility/permission-groups",
    component: _c7b4732e,
    name: "utility-permission-groups___es"
  }, {
    path: "/es/utility/permissions",
    component: _270ee9db,
    name: "utility-permissions___es"
  }, {
    path: "/es/utility/task-properties",
    component: _c72085dc,
    name: "utility-task-properties___es"
  }, {
    path: "/es/utility/torre-messenger-logs",
    component: _7a7a92eb,
    name: "utility-torre-messenger-logs___es"
  }, {
    path: "/es/utility/update-person-operator-flag",
    component: _a59ee3d0,
    name: "utility-update-person-operator-flag___es"
  }, {
    path: "/opportunity/recruitment-card/components",
    component: _93735634,
    name: "opportunity-recruitment-card-components___en___default"
  }, {
    path: "/opportunity/recruitment-card/processSteps",
    component: _180309f8,
    name: "opportunity-recruitment-card-processSteps___en___default"
  }, {
    path: "/en/client/detail/Components",
    component: _37ae7bc8,
    name: "client-detail-Components___en"
  }, {
    path: "/en/opportunity/recruitment-card/components",
    component: _93735634,
    name: "opportunity-recruitment-card-components___en"
  }, {
    path: "/en/opportunity/recruitment-card/processSteps",
    component: _180309f8,
    name: "opportunity-recruitment-card-processSteps___en"
  }, {
    path: "/es/client/detail/Components",
    component: _37ae7bc8,
    name: "client-detail-Components___es"
  }, {
    path: "/es/opportunity/recruitment-card/components",
    component: _93735634,
    name: "opportunity-recruitment-card-components___es"
  }, {
    path: "/es/opportunity/recruitment-card/processSteps",
    component: _180309f8,
    name: "opportunity-recruitment-card-processSteps___es"
  }, {
    path: "/en/messages/emma/:entity?",
    component: _f7b6f26e,
    name: "messages-emma-entity___en"
  }, {
    path: "/en/messages/gpt-prompt/:entity?",
    component: _cba60a5a,
    name: "messages-gpt-prompt-entity___en"
  }, {
    path: "/en/torre-geniuses/onboarding-flow/:flow",
    component: _182c3b30,
    name: "torre-geniuses-onboarding-flow-flow___en"
  }, {
    path: "/es/messages/emma/:entity?",
    component: _f7b6f26e,
    name: "messages-emma-entity___es"
  }, {
    path: "/es/messages/gpt-prompt/:entity?",
    component: _cba60a5a,
    name: "messages-gpt-prompt-entity___es"
  }, {
    path: "/es/torre-geniuses/onboarding-flow/:flow",
    component: _182c3b30,
    name: "torre-geniuses-onboarding-flow-flow___es"
  }, {
    path: "/messages/emma/:entity?",
    component: _f7b6f26e,
    name: "messages-emma-entity___en___default"
  }, {
    path: "/messages/gpt-prompt/:entity?",
    component: _cba60a5a,
    name: "messages-gpt-prompt-entity___en___default"
  }, {
    path: "/torre-geniuses/onboarding-flow/:flow",
    component: _182c3b30,
    name: "torre-geniuses-onboarding-flow-flow___en___default"
  }, {
    path: "/",
    component: _0f89e0ee,
    name: "index___en___default"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
